import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeContent } from './home-content';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HomeContentService {
    constructor(private http: HttpClient) {}

    getContent(): Observable<HomeContent> {
        return this.http.get<HomeContent>('data/home.json').pipe(shareReplay(1));
    }
}