<footer class="sa-footer">
    <div class="max-auto sa-grid pt40">
        <div class="sa-col flex-grow-1 d-flex flex-col plMd">
            <h4 class="mb20 p--heading">{{ name }}</h4>
            <div class="">
                <p class="p2" *ngFor="let line of addressList;">{{ line }}</p>
            </div>
            <a *ngIf="email" href="mailto:{{email}}" class="a fw300 mb20">{{ email }}</a>
            <a *ngIf="phone" href="tel:{{phone}}" class="a fw300 mb20">{{ phone }}</a>
            <div class="fw300 mb20">Built by <a href="https://surrey-apps.com" sa-link class="fw500">Surrey Apps</a></div>
        </div>
        <div class="plMd pr60 pb40">
            <p class="p2 mb10 fw600">Sitemap</p>
            <ul class="ul">
                <li *ngFor="let menuItem of mainMenu;" class="li"><a href="{{ menuItem.url }}" sa-link>{{ menuItem.label }}</a></li>
            </ul>
        </div>
        <div *ngIf="socialMenu.length > 0" class="plMd pr100">
            <p class="p2 mb20 fw500">Follow</p>
            <ul class="ul">
                <li *ngFor="let menuItem of socialMenu;" class="li"><a href="{{ menuItem.url }}" sa-link>{{ menuItem.label }}</a></li>
            </ul>
        </div>
    </div>
</footer>