<header class="sa-header plMd prMd sa-header--theme-{{theme}}" [ngClass]="slideInClass">
    <a sa-link [routerLink]="['/', 'home']" class="sa-header__logo">
        <ng-content select="[logo]"></ng-content>
    </a>
    <div class="flex-grow-1"></div>

    <!-- ---
        WIDESCREEN MENU
    --- -->
    <div class="sa-header__strip-menu d-flex align-center">
        <ul class="ul-reset d-flex space-between sa-header-strip-menu__main">
            <li *ngFor="let item of mainMenu;"><a sa-link [routerLink]="item.url" [theme]="theme" [selected]="selectedRoute && selectedRoute.indexOf(item.url) > -1">{{ item.label }}</a></li>
        </ul>
        <ul *ngIf="socialMenu && socialMenu.length > 0" class="ul-reset d-flex space-between sa-header-strip-menu__icon ml10">
            <li>
                <a href="" sa-link><sa-twitter class="lh0"></sa-twitter></a>
            </li>
            <li>
                <a href="" sa-link><sa-linkedin class="lh0"></sa-linkedin></a>
            </li>
            <li>
                <a href="" sa-link><sa-facebook class="lh0"></sa-facebook></a>
            </li>
        </ul>
        <button *ngIf="showAction" sa-btn (click)="takeActionHandler()" [theme]="theme" class="sa-header__action ml10">Take Action</button>
    </div>
    <button class="sa-header__menu-toggle hamburger hamburger--slider p0" [ngClass]="{'is-active': isSlideInVisible}" (click)="toggle()">
        <span class="hamburger-box">
            <span class="hamburger-inner hamburger-bg-{{theme}}"></span>
          </span>
    </button>

    <!-- ---
        NARROW SCREEN MENU
    --- -->
    <div class="sa-header__slide-in-menu">
        <div class="sa-header-slide-in-menu__content" [ngClass]="{'sa-header-slide-in-menu__content--show': showSlideInContent}">
            <ul class="ul-reset d-flex flex-col mb40">
                <li *ngFor="let item of mainMenu;" class="d-flex flex-center"><a sa-link [theme]="theme" class="a" (click)="navigateTo(item.url)">{{ item.label }}</a></li>
            </ul>
    
            <div *ngIf="socialMenu && socialMenu.length > 0" class="d-flex flex-center mb40">
                <ul class="ul-reset d-flex space-between sa-header-slide-in-menu__icon">
                    <li>
                        <a href="" sa-link><sa-twitter class="lh0"></sa-twitter></a>
                    </li>
                    <li>
                        <a href="" sa-link><sa-linkedin class="lh0"></sa-linkedin></a>
                    </li>
                    <li>
                        <a href="" sa-link><sa-facebook class="lh0"></sa-facebook></a>
                    </li>
                </ul>
            </div>
            
            <!-- <div *ngIf="showAction" class="d-flex flex-center mb40">
                <button sa-btn (click)="takeActionHandler()" theme="dark" size="large" class="">Take Action</button>
            </div> -->
        </div>
    </div>
</header>

