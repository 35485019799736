import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-title-panel',
  templateUrl: './title-panel.component.html',
  styleUrls: ['./title-panel.component.scss']
})
export class TitlePanelComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  textAlign = 'center';

  @Input()
  textColor = 'dark';
  
  constructor() { }

  ngOnInit(): void {
  }

}
