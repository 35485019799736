import { Component, Input, OnInit } from '@angular/core';
import { chunk } from 'lodash-es';

@Component({
  selector: 'sa-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  @Input()
  logos: string[] = [];

  logoRows: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.logoRows = chunk(this.logos, 4);
  }

}
