import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-one-image-panel',
  templateUrl: './one-image-panel.component.html',
  styleUrls: ['./one-image-panel.component.scss']
})
export class OneImagePanelComponent implements OnInit {
  @Input()
  imgSrc: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
