import { Component, OnInit, Input, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'sa-two-image-panel',
  templateUrl: './two-image-panel.component.html',
  styleUrls: ['./two-image-panel.component.scss']
})
export class TwoImagePanelComponent implements OnInit, OnDestroy {
  @Input()
  bgColor = 'white';

  @Input()
  isReverse = false;

  startAnimation = false;
  observer: IntersectionObserver;
  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {
    const el = this.elRef.nativeElement;
    const config: IntersectionObserverInit = {
      // root: el,
      rootMargin: '0px',
      threshold: 0.5
    }
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startAnimation = true;
        }
      });
    }, config);
    this.observer.observe(el);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  handleIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
    
  }

}
