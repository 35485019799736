import { Directive, HostBinding, OnInit } from '@angular/core';
import { SetViewHeightService } from './set-view-height.service';

@Directive({
  selector: '[saFitToViewHeight]'
})
export class FitToViewHeightDirective implements OnInit {
  @HostBinding('style.height') height;

  constructor(private setViewHeightService: SetViewHeightService) {}

  ngOnInit() {
    this.setViewHeightService.initWithoutResize();
    this.height = 'calc(var(--vh, 1vh) * 100)';
  }
}
