<div class="bg-{{bgColor}}">
  <div class="max-auto sa-grid pt60 pb60">
    <div class="sa-col plMd prMd pb20">
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="sa-col plMd prMd d-flex align-center">
        <ng-content select="[right]"></ng-content>
    </div>
  </div>
</div>
