import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'sa-lead-panel',
    templateUrl: './lead-panel.component.html',
    styleUrls: ['./lead-panel.component.scss']
})
export class LeadPanelComponent implements OnInit {
    @Input()
    text: string;

    @Input()
    actionText: string;

    @Output()
    actionHandler = new EventEmitter();

    showContent = false;

    constructor() {}

    ngOnInit() {
        setTimeout(() => {
            this.showContent = true;
        }, 200);
    }

    clickMe(ev: any) {
        this.actionHandler.emit();
    }
}