import { Component, OnInit } from '@angular/core';
import { HomeContent } from './data/home-content';
import { HomeContentService } from './data/home-content.service';
import { Observable } from 'rxjs/internal/Observable';
import { share } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'marketing-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  content$: Observable<HomeContent>;
  show1 = true;
  show2 = false;
  clientLogos = [
    'assets/img/coca-cola.svg',
    'assets/img/abbvie.svg',
    'assets/img/bms.svg',
    'assets/img/novartis.svg',
    'assets/img/sandoz.svg',
    'assets/img/regenerate.png',
    'assets/img/l&g.svg',
    'assets/img/edited/pfizer_edit.png',
  ];
  clientLogos$: Observable<string[]>;

  storyCopy = [
    'If you\'re wondering why',
    'a company that builds web products',
    'has such a simple website.',
    'It\'s because in our years of experience',
    'we\'ve uncovered the two single most important rules of web software',
    'And we\'d like to share them with you',
    'They are...'
  ];

  storyCopyPart2 = [
    'If you understand these two basic principles',
    'And want to put them front and center of your project',
  ];

  constructor(private homeContentService: HomeContentService) { }

  ngOnInit(): void {
    this.content$ = this.homeContentService.getContent();
    this.clientLogos$ = of(this.clientLogos);
    setInterval(() => {
      this.show1 = !this.show1;
      this.show2 = !this.show2;
    }, 3000);
  }

}
