import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SetViewHeightService {
  constructor() {}

  initWithoutResize() {
    this.setViewHeight();
  }

  setViewHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  initWithResize() {
    window.addEventListener('resize', this.setViewHeight);
  }
}
