import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './organisms/header/header.component';
import { FooterComponent } from './organisms/footer/footer.component';
import { RouterModule } from '@angular/router';
import { TwitterIcon } from './atoms/icon/twitter-icon.component';
import { FacebookIcon } from './atoms/icon/facebook-icon.component';
import { LinkedinIcon } from './atoms/icon/linkedin-icon.component';
import { ButtonComponent } from './atoms/button/button.component';
import { LeadPanelComponent } from './organisms/lead-panel/lead-panel.component';
import { TwoPanelComponent } from './organisms/two-panel/two-panel.component';
import { TwoImagePanelComponent } from './organisms/two-image-panel/two-image-panel.component';
import { OneImagePanelComponent } from './organisms/one-image-panel/one-image-panel.component';
import { LinkComponent } from './atoms/link/link.component';
import { TitlePanelComponent } from './organisms/title-panel/title-panel.component';
import { ComingSoonPanelComponent } from './organisms/coming-soon-panel/coming-soon-panel.component';
import { IntroTextComponent } from './organisms/intro-text/intro-text.component';
import { FitToViewHeightDirective } from './directives/fit-to-view-height/fit-to-view-height.directive';
import { ArticleHeadingComponent } from './organisms/article-heading/article-heading.component';
import { ArticleTextImageComponent } from './organisms/article-text-image/article-text-image.component';
import { ClientListComponent } from './organisms/client-list/client-list.component';
import { ChevronComponent } from './atoms/chevron/chevron.component';

var COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  ButtonComponent,
  LeadPanelComponent,
  TwoPanelComponent,
  TwoImagePanelComponent,
  OneImagePanelComponent,
  LinkComponent,
  TitlePanelComponent,
  ComingSoonPanelComponent,
  IntroTextComponent,
  FitToViewHeightDirective,
  ArticleHeadingComponent,
  ArticleTextImageComponent,
  ClientListComponent,
  ChevronComponent
]

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class UiModule {}
