<div class="two-image-panel bg-{{bgColor}}">
    <div class="two-image-panel__content max-auto sa-grid pt40 pb40" [ngClass]="{'row-reverse': isReverse}">
      <div class="sa-col plMd prMd d-flex align-center">
        <div class="animate-content animate-content--from-{{ isReverse ? 'right': 'left'}}"
        [ngClass]="{'animate-content--animate': startAnimation}">
            <ng-content select="[image]"></ng-content>
        </div>
      </div>
      <div class="sa-col plMd prMd d-flex align-center">
        <div class="animate-content animate-content--from-{{ isReverse ? 'left': 'right'}}"
        [ngClass]="{'animate-content--animate': startAnimation}">
            <ng-content select="[text]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  
