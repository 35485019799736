import { Component, Input, OnInit } from '@angular/core';
import { saTextUtils } from '@surrey-apps/utils';

@Component({
  selector: 'sa-intro-text',
  templateUrl: './intro-text.component.html',
  styleUrls: ['./intro-text.component.scss']
})
export class IntroTextComponent implements OnInit {
  @Input()
  heading = '';

  @Input()
  paragraphs: string[] = [];

  @Input()
  headingCharLimit = -1;

  trimmedHeading = '';
  amendedParagraphs = [];

  constructor() { }

  ngOnInit(): void {
    const charLimit = this.headingCharLimit;
    if (charLimit === -1 || this.heading.length < charLimit) {
      this.trimmedHeading = this.heading;
      this.amendedParagraphs = this.paragraphs;
    } else {
      const paragraphs = [...this.paragraphs];
      const splitHeading = saTextUtils.splitByIndex(this.heading, charLimit);
      paragraphs.unshift(splitHeading[1]);
      this.trimmedHeading = splitHeading[0];
      this.amendedParagraphs = paragraphs;
    }
  }

}
