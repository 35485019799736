import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-two-panel',
  templateUrl: './two-panel.component.html',
  styleUrls: ['./two-panel.component.scss']
})
export class TwoPanelComponent implements OnInit {
  @Input()
  bgColor = 'white';
  
  constructor() { }

  ngOnInit(): void {
  }

}
