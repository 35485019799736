import { Component } from '@angular/core';
import { SaMenu } from '@surrey-apps/ui';

@Component({
  selector: 'marketing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'marketing';

  mainMenu: SaMenu[] = [
    // { label: 'How it works', url: 'how-it-works', iconName: '' },
    // { label: 'Pricing', url: 'pricing', iconName: '' },
  ];

  socialMenu: SaMenu[] = [
    // { label: 'Twitter', url: 'twitter/handle', iconName: 'T' },
    // { label: 'LinkedIn', url: 'linkedId/link', iconName: 'LI' },
    // { label: 'Facebook', url: 'facebook/link', iconName: 'FB' },
  ];

  
  headerTheme = 'dark';

  footerDetails = {
    name: 'Regenerate Asset Management',
    // address: '5 Westgate,North Cave,Brough HU15 2NG, UK',
    address: 'david@regenerateam.com',
    phone: '',
    email: 'david@regenerateam.com'
  }

  learnMore() {
    console.log('learn');
  }
}
