import { Component, HostBinding, ViewEncapsulation, Input } from "@angular/core";
import { LinkThemes } from './link-themes.enum';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[sa-link], a[sa-link]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkComponent {
  @Input()
  selected = false;

  @Input()
  theme = LinkThemes.Light;

  @HostBinding('class.sa-link')
  get isLink() { return true; }

  @HostBinding('class.sa-link--selected')
  get isSelected() { return this.selected; }

  @HostBinding('class.sa-link--theme-light')
  get isLight() { return this.theme === LinkThemes.Light; }

  @HostBinding('class.sa-link--theme-dark')
  get isDark() { return this.theme === LinkThemes.Dark; }

  constructor() {}
}