
<div class="app h-100 d-flex flex-col">
  <div class="max-auto w-100">
    <sa-header [theme]="headerTheme" [mainMenu]="mainMenu" [socialMenu]="socialMenu">
      <div logo class="sa-logo">SA</div>
    </sa-header>
  </div>
  <div class="flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <sa-footer
    name="Surrey Apps" address="Surrey, UK"
    phone="01483214678" [mainMenu]="mainMenu" [socialMenu]="socialMenu"></sa-footer>
</div>