
export const saTextUtils = {
    splitByIndex: (text: string, index: number): string[] => {
        const nextSpaceIndex = saTextUtils.findNextSpaceIndex(text, index);
        const a = text.substring(0, nextSpaceIndex);
        const b = text.substring(nextSpaceIndex, text.length);
        return [a, b];
    },
    findNextSpaceIndex: (text: string, index: number): number => {
        let currentIndex = index;
        while (text.charAt(currentIndex) !== ' ') {
        currentIndex += 1;
        }
        return currentIndex;
    }
}