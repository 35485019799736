import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sa-article-text-image',
  templateUrl: './article-text-image.component.html',
  styleUrls: ['./article-text-image.component.scss']
})
export class ArticleTextImageComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  imgSrc: string;

  @Input()
  size: string;

  constructor() { }

  ngOnInit(): void {
  }

}
