import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface UiState {
    isSlideMenuOpen: boolean;
    [index:string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class UiState {
    private initialState = {
        isSlideMenuOpen: false,
    };

    private currentState = this.initialState;

    public state$: BehaviorSubject<any> = new BehaviorSubject(this.currentState);

    constructor() {}

    updateState(key, newValue) {
        this.currentState = Object.assign({}, this.currentState, {
            [key]: newValue
        });
        this.state$.next(this.currentState);
    }

    setIsSlideMenuOpen(isOpen: boolean) {
        this.updateState('isSlideMenuOpen', isOpen);
    }

    getIsSlideMenuOpen() {
        return this.state$.pipe(map(state => state.isSlideMenuOpen));
    }
}