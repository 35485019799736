import { Component, OnInit, Input, HostBinding, OnDestroy, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { SaMenu } from './main-menu';
import { HeaderTheme } from './header-theme.enum';
import { Router } from '@angular/router';
import { UiState } from '../../+state/ui-state.service';

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  selectedRoute: string[];

  @Input()
  mainMenu: SaMenu[] = [];

  @Input()
  socialMenu: SaMenu[] = [];

  @Input()
  theme: HeaderTheme = HeaderTheme.Light;

  @Input()
  showAction = false;

  @Output()
  takeAction = new EventEmitter();

  isMobile = false;
  isSlideInVisible = false;
  slideInClass = '';
  showSlideInContent = false;
  timer;
  bodyEl;

  constructor(
    private router: Router,
    private uiState: UiState
  ) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  toggle() {
    this.isSlideInVisible = !this.isSlideInVisible;
    this.slideInClass = this.isSlideInVisible ? 'sa-header__slide-in-menu--open' : '';
    this.uiState.setIsSlideMenuOpen(this.isSlideInVisible);
    this.timer = setTimeout(() => {
      this.showSlideInContent = !this.showSlideInContent;
    })
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    this.toggle();
  }

  takeActionHandler() {
    this.takeAction.emit();
  }
}
