<style>
    .home {
        position: relative;
        top: -90px;
    }
</style>
<div class="home">
    <div *ngFor="let text of storyCopy;" class="d-flex flex-center align-center" saFitToViewHeight>
        <div class="max-auto">
            <div class="plMd prMd">
                <p class="p">{{ text }}</p>
            </div>
        </div>
    </div>
    
    <!-- custom -->
    <div class="d-flex flex-center align-center bg-black" saFitToViewHeight>
        <div class="max-auto">
            <div class="plMd prMd">
                <p class="p text-light">1. keep it <strong>simple</strong></p>
            </div>
        </div>
    </div>

    <div class="d-flex flex-center align-center bg-black" saFitToViewHeight>
        <div class="max-auto">
            <div class="plMd prMd">
                <p class="p text-light">2. keep it <strong>fast</strong></p>
            </div>
        </div>
    </div>


    <div *ngFor="let text of storyCopyPart2;" class="d-flex flex-center align-center" saFitToViewHeight>
        <div class="max-auto">
            <div class="plMd prMd">
                <p class="p">{{ text }}</p>
            </div>
        </div>
    </div>
    
    <div class="d-flex flex-center align-center" saFitToViewHeight>
        <div class="max-auto">
            <div class="plMd prMd">
                <p class="p mb20">then we'd like to build it for you.</p>
                <a sa-btn theme="dark" href="mailto:ts.surreyapps@gmail.com">Send Us a Proposal</a>
            </div>
        </div>
    </div>

</div>