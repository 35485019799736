import { Component, OnInit, Input } from '@angular/core';
import { SaMenu } from '../header/main-menu';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  address: string;

  @Input()
  phone: string;

  @Input()
  email: string;

  @Input()
  mainMenu: SaMenu[] = [];

  @Input()
  socialMenu: SaMenu[] = [];

  addressList: string[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.address && this.address.length > 0) {
      this.addressList = this.address.split(',');
    }
  }

}
